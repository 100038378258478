<script>
import RegionBase from './RegionBase'
import { americasManifest } from '../ea-vgas-giveaway-manifest'

export default {
  extends: RegionBase,
  data() {
    return {
      regionId: 'americas',
      activeIntro: 'Giveaways for The Americas',
      gameList: americasManifest
    }
  },
  metaInfo() {
    return {
      title: 'The Game Awards Giveaways - The Americas',
      titleTemplate: '%s | Surf Giveaways'
    }
  }
}
</script>
<style lang="scss" src="@/assets/themes/ea-vgas/scss/main.scss" />
